<template>
  <div>
    <b-alert
      variant="success"
      show
      style="width: 390px; margin: 0 auto;"
    >
      <div class="alert-body">
        <p>
              <span> 🙌</span>
              
              <strong>1527 Settlements Analyzed </strong>
              
              
              <b-link
                class="alert-link"
                href="https://purpletrac.io"
                target="blank"
              >Learn More Here</b-link>
              
        </p>
      </div>
    </b-alert>

    <br>
    <!-- Upload Box with CSV Restriction -->
    
    <b-card
          border-variant = "primary"
          bg-variant="transparent"
          class = "shadow-none custom-card"
    >
    <h4 class="bold-heading" >Settlement Details</h4>
    
    <b-form-file
      id="csv-upload"
      accept=".csv"
      v-model="csvFile"
      @input="validateFile"
      :state="fileState"
      placeholder="Upload a CSV format Settlement..."
    >
      <template #file-name="{ names }">{{ names.join(', ') }}</template>
    </b-form-file>
    <b-form-invalid-feedback v-if="fileState === false">Please upload a valid CSV file.</b-form-invalid-feedback>
    
    <br>
    <br>
    <!-- Row 2: Select Year, Week, and Settlement Date -->
    
    <b-row>
      <b-col md="12" lg="4">
        <b-form-datepicker
          v-model="settlementDate"
          today-button
          reset-button
          locale="en"
          placeholder="Date"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          class="transparent-bg"
        />
      </b-col>

      <b-col md="12" lg="4">
        <v-select
          v-model="selectedYear"
          :options="years"
          placeholder="Year"
          

        />
      </b-col>

      <b-col md="12" lg="4">
        <v-select
          v-model="selectedWeek"
          :options="weeks"
          placeholder="Week"
          

        />
      </b-col>
      
        
      
    </b-row>

    <hr />
    <h4 class="bold-heading">
        Weekly Expenses
    </h4>
    <!-- Row 3: Repeater for Expenses and Date Select -->
    <b-form @submit.prevent="submitForm">
      <div v-for="(expense, index) in expenses" :key="index">
        <b-row>
          <b-col md="4">
            <b-form-input
              v-model="expense.name"
              placeholder="Expense Description"
              size="sm"
            />
          </b-col>
          <b-col md="2">
            <b-form-input
              v-model="expense.amount"
              placeholder="Amount"
              type="number"
              size="sm"
            />
          </b-col>
          <b-col md="3">
          
            <b-form-datepicker
              v-model="expense.date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              style="white-space: nowrap;"
              locale="en"
              placeholder="Date"
              today-button
              reset-button
              size="sm"
              
            />
          </b-col>
          <b-col md="3">
            <b-button
              variant="outline-danger"
              size="sm"
              @click="removeExpense(index)"
            >
              Remove
            </b-button>
          </b-col>
        </b-row>
        <hr />
      </div>

      <b-button  variant="outline-success" size="sm" @click="addExpense">Add Expense</b-button>
    </b-form>

    <hr />
    <div class="demo-inline-spacing">
    <h4 class="bold-heading">
        AI Engine
    </h4>
      <b-form-checkbox
        checked="true"
        name="check-button"
        switch
        inline
        v-model="gptSwitch"
        @change="toggleGPT"
        label="AI Engine"
      >
      </b-form-checkbox>
      <span v-if="gptSwitch" inline class="text-danger"> Using GPT credits  </span>
      
    </div>
    <!-- GPT Settings -->
    
      <!-- GPT Version -->
      <div>
        <b-form-group> 
          <span>Model</span>
          <b-form-radio-group v-model="gptVersion" name="gpt-version">
          
            <b-form-radio value="GPT4" :disabled="!gptSwitch"><strong>GPT-4</strong></b-form-radio>
         
            <b-form-radio value="GPT4o" :disabled="!gptSwitch"><strong>GPT-4o</strong></b-form-radio>
          
          </b-form-radio-group>
        </b-form-group>
      
    
    <hr />
    
    <b-row>
    <!-- Text input for Analysis -->
    <b-form-textarea
      v-model="analysisQuery"
      style="min-height:140px;"
      placeholder="What are areas of improvement to add more to the bottom line"
      class="transparent-bg"
      :disabled="!gptSwitch"
    />
    </b-row>
    <hr/>
    <b-row>
      <!-- Creativity Slider -->
      <b-col md="12" lg="12">
        <b-form-group label="Creativity">
          <vue-slider v-model="creativity" :min="0" :max="100" class="mb-2 vue-slider-success" :direction="direction" :disabled="!gptSwitch"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
    </div>
    </div>
    <!-- Button to Run Analysis with Spinner -->
      <b-row class="justify-content-center">
        <b-button
          :disabled="!canSubmit"
          variant="primary"
          @click="runAnalysis"
        >
          <b-spinner v-if="loading" small />
          Submit
        </b-button>
      </b-row>
    </div>
    
    
    
    <!-- Login Modal Trigger if User is not logged in -->
    <b-modal
      id="login-modal"
      title="Login Required"
      v-if="!isAdmin"
      ok-only
      ok-title="Login"
      @ok="redirectToLogin"
    >
      <p>You need to log in or register to continue.</p>
      <b-button variant="primary" size="lg" @click="redirectToRegister">Register</b-button>
    </b-modal>
    </b-card>
  
  
</template>

<script>

import {
  BFormFile,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton,
  BModal,
  BSpinner,
  VBPopover,
  VBModal,
  BAlert,
  BCard,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'
import axios from 'axios'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import store from '@/store/index'
import {
    serverUri
} from '@/config'
import http from "@/services/http";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    vSelect,
    VueSlider,
    BFormFile,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BModal,
    BSpinner,
    VBPopover,
    VBModal,
    BAlert,
    BCard,
    BCardText,
    ToastificationContent,
    BFormCheckbox,
  },
  
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      csvFile: null,
      selectedYear: null,
      selectedWeek: null,
      settlementDate: null,
      expenses: [],
      gptVersion: 'GPT4',
      creativity: 50,
      dir: 'ltr',
      analysisQuery: '',
      loading: false,
      fileState: null,
      isAdmin: JSON.parse(localStorage.getItem('userData')).role === 'Admin',
      gptSwitch: false,
    }
  },
  computed: {
    years() {
      return [2023, 2024, 2025] // Example years
    },
    weeks() {
      return Array.from({ length: 52 }, (_, i) => i + 1)
    },
    canSubmit() {
      return this.csvFile && this.selectedYear && this.selectedWeek && this.settlementDate
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  methods: {
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },
    validateFile() {
      if (this.csvFile && this.csvFile.type !== 'text/csv') {
        this.fileState = false
      } else {
        this.fileState = true
      }
    },
    addExpense() {
      this.expenses.push({ name: '', amount: '', date: null })
    },
    removeExpense(index) {
      this.expenses.splice(index, 1)
    },
    async runAnalysis() {
      if (!this.isAdmin) {
        this.$bvModal.show('login-modal');
        return;
      }

      this.loading = true;

      try {
        const accessToken = JSON.parse(localStorage.getItem('userData')).accessToken;

        const formData = new FormData();
        formData.append('file', this.csvFile);
        formData.append('year', this.selectedYear);
        formData.append('week', this.selectedWeek);
        formData.append('company_id', getUserData().company_id);
        formData.append('company_name', getUserData().company_name);
        formData.append('statement_date', this.settlementDate);
        formData.append('expenses', JSON.stringify(this.expenses)); // Make sure to serialize expenses
        formData.append('gpt_version', this.gptVersion);
        formData.append('query', this.analysisQuery);

        const response = await axios.post(`${serverUri}/settlement/saveanddeconstruct`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          this.showToast('success', 'File has been processed.');
          this.$router.push('/gpt-archive');
        } else {
          this.showToast('error', 'CSV not processed.');
        }
      } catch (error) {
        this.handleError(error);
      } finally { this.loading = false}
    
    },
      
    
    toggleGPT(newValue) {
      if (newValue) {
        this.$swal({
          title: 'Notice',
          text: 'Activating this feature requires credits.',
          
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-primary',
        });
      }
    },

    handleError(error) {
      let errorMessage = 'Failed to process request. Please try again.';
      if (error.response && error.response.status === 403) {
        errorMessage = 'Login Data Access Expired. Log out and log back in.';
      }
      this.showToast('error', errorMessage);
    },

    redirectToLogin() {
      this.$router.push('/login')
    },
    redirectToRegister() {
      this.$router.push('/register')
    }
  }
}
</script>
<style>
.transparent-bg {
  background-color: transparent !important;
   /* Optional: Customize border */
  color: #fff; /* Customize text color */
  padding: 10px; /* Customize padding */
}

.bold-heading {
  font-weight: bold;
}
.custom-card {
  border-width: 2.5px !important;
  border-radius: 8px;
  min-width: 400px;
  max-width: 45%; /* Adjust the card width */
  padding: 20px;
  margin: 0 auto; /* Center the card */
}

.b-alert{
  
  width: 250px !important;
  margin: 0 auto;
}
.vue-slider-success .vue-slider-rail {
  background-color: rgba(255, 255, 255, 0); 
  height: 6px; /* Adjust track height */
}

.vue-slider-success .vue-slider-dot {
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
  width: 14px; /* Thumb width */
  height: 14px; /* Thumb height */
}
</style>

<style scoped>
.demo-inline-spacing {
  display: flex;
  gap: 10px;
}
</style>
